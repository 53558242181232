<template>
  <div class="box">
    <Breadcrumb class="navigation">
      <BreadcrumbItem v-for="item in breadMenuData"
        :to="item.path">
        {{ item.name }}
      </BreadcrumbItem>
    </Breadcrumb>
    <div class="business-introduce-box">
      <p class="tit">1. {{ $t('key1005606') }}</p>
      <p class="sp">{{ $t('key1005607') }} </p>
      <p class="sp">{{ $t('key1005608') }}</p>
      <p class="sp1">① {{ $t('key1005609') }}</p>
      <p class="sp1">{{ $t('key1005610') }}//www.tongtool.com/）授权开通。</p>
      <p class="sp1">{{ $t('key1005611') }}</p>
      <p class="sp1">② {{ $t('key1005612') }}</p>
      <p class="sp1">{{ $t('key1005613') }}</p>
      <p class="sp1">③ {{ $t('key1005614') }}</p>
      <p class="sp1">{{ $t('key1005615') }}</p>
      <p class="tit">2. {{ $t('key1005616') }}</p>
      <p class="sp">{{ $t('key1005617') }}</p>
      <p class="sp">{{ $t('key1005608') }}</p>
      <p class="sp1">① {{ $t('key1005618') }}</p>
      <p class="sp1">② {{ $t('key1005619') }}</p>
      <p class="tit">3. {{ $t('key1005620') }}</p>
      <p class="sp">{{ $t('key1005621') }}</p>
      <p class="sp">{{ $t('key1005608') }}</p>
      <p class="sp1"> ① {{ $t('key1005622') }}</p>
      <p class="sp1">② {{ $t('key1005623') }}</p>
    </div>
  </div>
</template>

<script>
import { aliasb039bd6e4486415fba0a6b42f8cd95d1 } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';

export default {
  name: 'businessIntroduce',
  mixins: [Mixin],
  data () {
    return {
      breadMenuData: [{
        path: '/index',
        name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005492')
      },{
        path: '/businessIntroduce',
        name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005624')
      }]
    };
  },
  created () {

  },
  props: {},
  watch: {},
  methods: {}
};
</script>

<style scoped lang="less">
.box {
  width: 1200px;
  margin: 0 auto;
  .navigation {
    padding: 10px;
  }
  .business-introduce-box {
    background-color: #ffffff;
    margin-bottom: 20px;
    padding: 24px 32px 24px 32px;
    line-height: 32px;
    color: #000000;
    .tit {
      font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
      font-weight: 700;
      margin-top: 30px;
    }
    .tit:first-child{
      margin-top: 0;
    }
    .sp1 {
      padding-left: 18px;
    }
  }
}
</style>
